<script setup lang="ts">
import { ApplicationLogo } from "~/components"
import { onMounted } from "vue"

import { Link } from "@inertiajs/vue3"

import { delegate, Placement, roundArrow } from "tippy.js"

const setUpContextTippies = () => {
  delegate("body", {
    target: "[data-tippy-context]",
    arrow: roundArrow,
    animation: "scale",
    theme: "context",
    appendTo: () => document.body,
    onMount (instance) {
      instance.popperInstance?.setOptions({
        placement: instance.reference.getAttribute("data-placement") as Placement,
      })
    },
    onShow (instance) {
      if (instance.reference.getAttribute("data-tippy-context") === "false") return false
    },
  })
}

const setUpHelpTippies = () => {
  delegate("body", {
    target: "[data-tippy-help]",
    arrow: roundArrow,
    animation: "scale",
    theme: "help",
    appendTo: "parent",
    onMount (instance) {
      instance.popperInstance?.setOptions({
        placement: instance.reference.getAttribute("data-placement") as Placement,
      })
    },
    onShow (instance) {
      if (!instance.reference?.getAttribute("data-tippy-content") || instance.reference?.getAttribute("data-tippy-content") === "") return false
    },
  })
}

onMounted(() => {
  setUpContextTippies()
  setUpHelpTippies()
})
</script>

<template>
  <main class="relative overflow-hidden">
    <div class="flex flex-col items-center min-h-screen pt-6 md:justify-center md:pt-0 md:bg-gray-200">
      <div class="relative grid w-full p-10 pt-6 overflow-visible bg-white rounded-md md:max-w-lg lg:grid-cols-2 lg:max-w-4xl lg:space-x-12 lg:items-end lg:p-12 lg:pt-8 md:shadow-lg">
        <div class="lg:pr-12">
          <div class="mb-4">
            <Link href="/">
              <ApplicationLogo class="text-gray-500 fill-current h-14 app-logo" />
            </Link>
          </div>
          <slot />
        </div>
        <slot name="illustration" />
      </div>
    </div>
  </main>
</template>
